/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

import $ from 'jquery';
window.$ = window.jQuery = $;
window.moment = require('moment');
window.moment.locale('ru');
window.toastr = require('toastr');

import 'admin-lte/plugins/bootstrap-slider/bootstrap-slider.min.js';
//import 'admin-lte/plugins/bootstrap-colorpicker/js/bootstrap-colorpicker.min.js';
//import 'admin-lte/plugins/jquery/jquery.min.js';
import 'admin-lte/plugins/bootstrap/js/bootstrap.bundle.min.js';
//import 'bootstrap/dist/js/bootstrap.bundle.min.js';
//import 'bootstrap/js/dist/modal.js';
import 'jquery-ui/ui/widgets/datepicker.js';
import 'jquery-ui/ui/widgets/autocomplete';
import 'admin-lte/plugins/datatables/jquery.dataTables.min.js';
import 'daterangepicker/daterangepicker.js';
import 'moment/dist/moment.js';
import 'admin-lte/plugins/datatables-bs4/js/dataTables.bootstrap4.min.js';
import 'admin-lte/plugins/datatables-responsive/js/dataTables.responsive.min.js';
import 'admin-lte/plugins/datatables-responsive/js/responsive.bootstrap4.min.js';
import 'admin-lte/plugins/select2/js/select2.full.min.js';
import 'admin-lte/plugins/ion-rangeslider/js/ion.rangeSlider.min.js';
import 'admin-lte/plugins/toastr/toastr.min.js';
//import 'toastr/build/toastr.min.js';

//require('admin-lte');

//window.Vue = require('vue').default;

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

//Vue.component('example-component', require('./components/ExampleComponent.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

// const app = new Vue({
//     el: '#app',
// });
